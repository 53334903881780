import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { ReportAboutSection, ReportAboutTextBox, ReportBtnBox, ReportFuncBox } from "component/report/item";
import { Popup } from "component/basic/popup";

const ReportAbout = (props) => {
    const navigate = useNavigate();
    const [popupData,setPopupData] =  useState(null);

    const aboutData = [
        {
            img:"/assets/images/img/report_img_0.png",
            title:"<span class='gTextColor'>오토노바 디테일링 리포트</span>는<br/>차량의 건강을 확인하고 진단합니다",
            text:"차량의 도장은 그 차의 아름다움을 결정짓는 중요한 부분 중 하나이며도장 상태의 정확한 점검은 차량의 건강을 확인하고, 향후 유지보수에 필요한 조치를 결정하는 데 도움을 줍니다.<br/><br/>디테일링은 단순히 차량을 위생적으로, 깨끗하게 관리하는 것이 아닌 도장을 보존/관리해 고객님의 소중한 자산가치를 보호해주는 것을 시작으로 종합적인 차량관리 솔루션을 제공합니다."
        },
        {
            img:"/assets/images/img/report_img_1.png",
            title:"차량의 자산가치를<br/>보호하고 관리하기 위해<br/><span class='gTextColor'>디테일링 리포트</span>를 제공합니다",
            text:"차량은 우리의 일상에서 뗄 수 없는 존재이며 차량 상태는 자산가치와 직결됩니다. 오토노바는 디테일링 리포트를 통해 차량의 도장 상태를 면밀하게 측정해 고객님의 소중한 차량을 오랫동안 아름답게 유지할 수 있는 과학적인 데이터를 제공합니다."
        }
    ]

    const funcData = [
        {
            img:"report_funcImg_0.png",
            title:"도장 두께 & 광택도 분석 리포트",
            text:"차량의 파츠 별 <b>도장 두께 정보</b>와 <b>광택도 정보</b>를 측정해 현 도장 상태를 바탕으로 차량관리를 위한 가이드라인을 제공합니다."
        },
        {
            img:"report_funcImg_1.png",
            title:"스크래치 & 보수 도장 상태 리포트",
            text:"고객님이 미처 알지 못했던 차량의 <b>스크래치 및 보수 도장 상태</b> 정보를 제공해 후속 조치를 위한 가이드라인을 제공합니다."
        },
        {
            img:"report_funcImg_2.png",
            title:"종합 평가점수 제공",
            text:"차량 상태를 바탕으로 리포트 결과와 종합 평가점수를 부여해 높은 점수를 유지하실 경우 <b>구독권 할인 혜택</b>을 제공합니다."
        }
    ]

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="report/about"
                    data={[
                        {id:"report/about",category:"소개"},
                        {id:"report/detail/new",category:"리포트"},
                        {id:"report/list",category:"내역"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                <ReportAboutSection>
                    {aboutData?.map((item,i)=>(
                        <ReportAboutTextBox
                            key={i}
                            title={item.title}
                            text={item.text}
                            img={item.img}
                        />
                    ))}
                </ReportAboutSection>
                <ReportAboutSection>
                    <PageTitle
                        contents={"오토노바의<br/><span class='gTextColor'>디테일링 리포트</span> 핵심 기능"}
                        subTitle={null}
                    />
                    <ReportFuncBox
                        addClass=""
                        data={funcData}
                    />
                </ReportAboutSection>
                <ReportBtnBox
                    text="오토노바의 특별한 디테일링을 바로 시작해보세요"
                    contents="디테일링 시작"
                    func={()=>{navigate("/detailing")}}
                />
            </ContentsSection>
            <FooterManu menuChk="3"/>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default ReportAbout;