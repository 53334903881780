function ReportCarImg(data){

    return (
        <div className="reportCarImg">
        {data.type === "aLine" ? 
        <>
            <svg width="245" height="182" viewBox="0 0 245 182" fill="none">
            <rect width="245" height="182" transform="matrix(-1 0 0 1 245 -0.000244141)" fill="url(#pattern0_1523_12426)"/>
            <path d="M110 37.999C110 35.7899 111.791 33.999 114 33.999H120C122.209 33.999 124 35.7899 124 37.999V41.999C124 44.2082 122.209 45.999 120 45.999H111C110.448 45.999 110 45.5513 110 44.999V37.999Z" className={`reportcarImgIcon t1 ${data.t1Color}`}/>
            <path d="M113.859 38.4834V37.7568H117.334V38.4834H116.033V41.999H115.16V38.4834H113.859ZM119.684 37.7568V41.999H118.799V38.5947H118.775L117.803 39.2041V38.4248L118.852 37.7568H119.684Z" fill="white"/>
            <path d="M72 15.999C72 13.7899 73.7909 11.999 76 11.999H82C84.2091 11.999 86 13.7899 86 15.999V19.999C86 22.2082 84.2091 23.999 82 23.999H73C72.4477 23.999 72 23.5513 72 22.999V15.999Z" className={`reportcarImgIcon t2 ${data.t2Color}`}/>
            <path d="M75.4346 16.4834V15.7568H78.9092V16.4834H77.6084V19.999H76.7354V16.4834H75.4346ZM79.4307 19.999L79.4248 19.3604L80.9482 17.96C81.3438 17.582 81.5576 17.3477 81.5576 17.0107C81.5576 16.6328 81.2734 16.3984 80.8838 16.4014C80.4854 16.3984 80.2246 16.6504 80.2275 17.0576H79.3896C79.3867 16.2285 80.002 15.6982 80.8955 15.6982C81.8037 15.6982 82.4072 16.2168 82.4072 16.9521C82.4072 17.4355 82.1729 17.8311 81.2998 18.6221L80.6553 19.249V19.2725H82.4658V19.999H79.4307Z" fill="white"/>
            <path d="M19 3.99902C19 1.78988 20.7909 -0.000976406 23 -0.000976213L29 -0.000975688C31.2091 -0.000975495 33 1.78989 33 3.99902L33 7.99902C33 10.2082 31.2091 11.999 29 11.999L20 11.999C19.4477 11.999 19 11.5513 19 10.999L19 3.99902Z" className={`reportcarImgIcon t3 ${data.t3Color}`}/>
            <path d="M22.3496 4.4834L22.3496 3.75684L25.8242 3.75684L25.8242 4.4834L24.5234 4.4834L24.5234 7.99902L23.6504 7.99902L23.6504 4.4834L22.3496 4.4834ZM27.916 8.05762C26.9844 8.05762 26.3164 7.54492 26.2988 6.80371L27.1895 6.80371C27.2041 7.12012 27.5117 7.32813 27.9219 7.33106C28.3437 7.32813 28.6455 7.09375 28.6426 6.75098C28.6455 6.40527 28.3379 6.16211 27.8398 6.15918L27.4473 6.15918L27.4473 5.50879L27.8398 5.50879C28.2588 5.50879 28.5518 5.28027 28.5488 4.94629C28.5518 4.62109 28.3027 4.39844 27.9277 4.40137C27.5527 4.39844 27.2451 4.60938 27.2305 4.93457L26.3867 4.93457C26.4014 4.20508 27.0576 3.69824 27.9277 3.69824C28.8125 3.69824 29.4014 4.22266 29.3984 4.89356C29.4014 5.36816 29.0703 5.70508 28.5957 5.79004L28.5957 5.8252C29.2197 5.90137 29.5625 6.2793 29.5566 6.80957C29.5625 7.53613 28.874 8.05762 27.916 8.05762Z" fill="white"/>
            <path d="M14 50.999C14 48.7899 15.7909 46.999 18 46.999H25C27.2091 46.999 29 48.7899 29 50.999V54.999C29 57.2082 27.2091 58.999 25 58.999H15C14.4477 58.999 14 58.5513 14 57.999V50.999Z" className={`reportcarImgIcon a4 ${data.a4Color}`}/>
            <path d="M18.4414 54.999H17.4922L18.9629 50.7568H20.0938L21.5586 54.999H20.6152L20.2959 54.0205H18.7607L18.4414 54.999ZM18.9834 53.335H20.0732L19.543 51.7119H19.5137L18.9834 53.335ZM21.9453 54.249V53.5518L23.7383 50.7568H24.8457V53.54H25.3848V54.249H24.8457V54.999H24.0078V54.249H21.9453ZM22.8359 53.54H24.0195V51.7236H23.9785L22.8359 53.5049V53.54Z" fill="white"/>
            <path d="M49 78.999C49 76.7899 50.7909 74.999 53 74.999H60C62.2091 74.999 64 76.7899 64 78.999V82.999C64 85.2082 62.2091 86.999 60 86.999H50C49.4477 86.999 49 86.5513 49 85.999V78.999Z" className={`reportcarImgIcon a3 ${data.a3Color}`}/>
            <path d="M53.4971 82.999H52.5479L54.0186 78.7568H55.1494L56.6143 82.999H55.6709L55.3516 82.0205H53.8164L53.4971 82.999ZM54.0391 81.335H55.1289L54.5986 79.7119H54.5693L54.0391 81.335ZM58.6416 83.0576C57.71 83.0576 57.042 82.5449 57.0244 81.8037H57.915C57.9297 82.1201 58.2373 82.3281 58.6475 82.3311C59.0693 82.3281 59.3711 82.0938 59.3682 81.751C59.3711 81.4053 59.0635 81.1621 58.5654 81.1592H58.1729V80.5088H58.5654C58.9844 80.5088 59.2773 80.2803 59.2744 79.9463C59.2773 79.6211 59.0283 79.3984 58.6533 79.4014C58.2783 79.3984 57.9707 79.6094 57.9561 79.9346H57.1123C57.127 79.2051 57.7832 78.6982 58.6533 78.6982C59.5381 78.6982 60.127 79.2227 60.124 79.8936C60.127 80.3682 59.7959 80.7051 59.3213 80.79V80.8252C59.9453 80.9014 60.2881 81.2793 60.2822 81.8096C60.2881 82.5361 59.5996 83.0576 58.6416 83.0576Z" fill="white"/>
            <path d="M84 102.999C84 100.79 85.7909 98.999 88 98.999H95C97.2091 98.999 99 100.79 99 102.999V106.999C99 109.208 97.2091 110.999 95 110.999H85C84.4477 110.999 84 110.551 84 109.999V102.999Z" className={`reportcarImgIcon a2 ${data.a2Color}`}/>
            <path d="M88.582 106.999H87.6328L89.1035 102.757H90.2344L91.6992 106.999H90.7559L90.4365 106.021H88.9014L88.582 106.999ZM89.124 105.335H90.2139L89.6836 103.712H89.6543L89.124 105.335ZM92.1562 106.999L92.1504 106.36L93.6738 104.96C94.0693 104.582 94.2832 104.348 94.2832 104.011C94.2832 103.633 93.999 103.398 93.6094 103.401C93.2109 103.398 92.9502 103.65 92.9531 104.058H92.1152C92.1123 103.229 92.7275 102.698 93.6211 102.698C94.5293 102.698 95.1328 103.217 95.1328 103.952C95.1328 104.436 94.8984 104.831 94.0254 105.622L93.3809 106.249V106.272H95.1914V106.999H92.1562Z" fill="white"/>
            <path d="M124 122.999C124 120.79 125.791 118.999 128 118.999H134C136.209 118.999 138 120.79 138 122.999V126.999C138 129.208 136.209 130.999 134 130.999H125C124.448 130.999 124 130.551 124 129.999V122.999Z" className={`reportcarImgIcon a1 ${data.a1Color}`}/>
            <path d="M128.606 126.999H127.657L129.128 122.757H130.259L131.724 126.999H130.78L130.461 126.021H128.926L128.606 126.999ZM129.148 125.335H130.238L129.708 123.712H129.679L129.148 125.335ZM133.81 122.757V126.999H132.925V123.595H132.901L131.929 124.204V123.425L132.978 122.757H133.81Z" fill="white"/>
            <path d="M174 109.999C174 107.79 175.791 105.999 178 105.999H184C186.209 105.999 188 107.79 188 109.999V113.999C188 116.208 186.209 117.999 184 117.999H175C174.448 117.999 174 117.551 174 116.999V109.999Z" className={`reportcarImgIcon b2 ${data.b2Color}`}/>
            <path d="M177.616 113.999V109.757H179.304C180.238 109.757 180.713 110.208 180.71 110.847C180.713 111.345 180.385 111.658 179.948 111.755V111.796C180.426 111.819 180.877 112.2 180.88 112.827C180.877 113.501 180.373 113.999 179.415 113.999H177.616ZM178.495 113.272H179.239C179.749 113.275 179.969 113.064 179.966 112.745C179.969 112.388 179.696 112.136 179.263 112.136H178.495V113.272ZM178.495 111.532H179.175C179.544 111.535 179.822 111.327 179.819 110.987C179.822 110.68 179.594 110.472 179.192 110.472H178.495V111.532ZM181.413 113.999L181.407 113.36L182.931 111.96C183.326 111.582 183.54 111.348 183.54 111.011C183.54 110.633 183.256 110.398 182.866 110.401C182.468 110.398 182.207 110.65 182.21 111.058H181.372C181.369 110.229 181.984 109.698 182.878 109.698C183.786 109.698 184.39 110.217 184.39 110.952C184.39 111.436 184.155 111.831 183.282 112.622L182.638 113.249V113.272H184.448V113.999H181.413Z" fill="white"/>
            <path d="M197 93.999C197 91.7899 198.791 89.999 201 89.999H207C209.209 89.999 211 91.7899 211 93.999V97.999C211 100.208 209.209 101.999 207 101.999H198C197.448 101.999 197 101.551 197 100.999V93.999Z" className={`reportcarImgIcon b1 ${data.b1Color}`}/>
            <path d="M201.041 97.999V93.7568H202.729C203.663 93.7568 204.138 94.208 204.135 94.8467C204.138 95.3447 203.81 95.6582 203.373 95.7549V95.7959C203.851 95.8193 204.302 96.2002 204.305 96.8271C204.302 97.501 203.798 97.999 202.84 97.999H201.041ZM201.92 97.2725H202.664C203.174 97.2754 203.394 97.0645 203.391 96.7451C203.394 96.3877 203.121 96.1357 202.688 96.1357H201.92V97.2725ZM201.92 95.5322H202.6C202.969 95.5352 203.247 95.3271 203.244 94.9873C203.247 94.6797 203.019 94.4717 202.617 94.4717H201.92V95.5322ZM206.666 93.7568V97.999H205.781V94.5947H205.758L204.785 95.2041V94.4248L205.834 93.7568H206.666Z" fill="white"/>
            </svg>
            <img src="/assets/images/img/car_svg_bg_aLine.png"/>
        </>
        :
        <>
            <svg width="270" height="200" viewBox="0 0 270 200" fill="none">
            <rect x="0.5" width="269" height="200" fill="url(#pattern0_1358_27973)"/>
            <path d="M135.5 40C135.5 37.7909 137.291 36 139.5 36H144.5C146.709 36 148.5 37.7909 148.5 40V44C148.5 46.2091 146.709 48 144.5 48H136.5C135.948 48 135.5 47.5523 135.5 47V40Z" className={`reportcarImgIcon t1 ${data.t1Color}`}/>
            <path d="M138.859 40.4844V39.7578H142.334V40.4844H141.033V44H140.16V40.4844H138.859ZM144.684 39.7578V44H143.799V40.5957H143.775L142.803 41.2051V40.4258L143.852 39.7578H144.684Z" fill="white"/>
            <path d="M176.5 18C176.5 15.7909 178.291 14 180.5 14H186.5C188.709 14 190.5 15.7909 190.5 18V22C190.5 24.2091 188.709 26 186.5 26H177.5C176.948 26 176.5 25.5523 176.5 25V18Z" className={`reportcarImgIcon t2 ${data.t2Color}`}/>
            <path d="M179.935 18.4844V17.7578H183.409V18.4844H182.108V22H181.235V18.4844H179.935ZM183.931 22L183.925 21.3613L185.448 19.9609C185.844 19.583 186.058 19.3486 186.058 19.0117C186.058 18.6338 185.773 18.3994 185.384 18.4023C184.985 18.3994 184.725 18.6514 184.728 19.0586H183.89C183.887 18.2295 184.502 17.6992 185.396 17.6992C186.304 17.6992 186.907 18.2178 186.907 18.9531C186.907 19.4365 186.673 19.832 185.8 20.623L185.155 21.25V21.2734H186.966V22H183.931Z" fill="white"/>
            <path d="M228.5 4C228.5 1.79086 230.291 0 232.5 0H238.5C240.709 0 242.5 1.79086 242.5 4V8C242.5 10.2091 240.709 12 238.5 12H229.5C228.948 12 228.5 11.5523 228.5 11V4Z" className={`reportcarImgIcon t3 ${data.t3Color}`}/>
            <path d="M231.85 4.48438V3.75781H235.324V4.48438H234.023V8H233.15V4.48438H231.85ZM237.416 8.05859C236.484 8.05859 235.816 7.5459 235.799 6.80469H236.689C236.704 7.12109 237.012 7.3291 237.422 7.33203C237.844 7.3291 238.146 7.09473 238.143 6.75195C238.146 6.40625 237.838 6.16309 237.34 6.16016H236.947V5.50977H237.34C237.759 5.50977 238.052 5.28125 238.049 4.94727C238.052 4.62207 237.803 4.39941 237.428 4.40234C237.053 4.39941 236.745 4.61035 236.73 4.93555H235.887C235.901 4.20605 236.558 3.69922 237.428 3.69922C238.312 3.69922 238.901 4.22363 238.898 4.89453C238.901 5.36914 238.57 5.70605 238.096 5.79102V5.82617C238.72 5.90234 239.062 6.28027 239.057 6.81055C239.062 7.53711 238.374 8.05859 237.416 8.05859Z" fill="white"/>
            <path d="M238.5 50C238.5 47.7909 240.291 46 242.5 46H249.5C251.709 46 253.5 47.7909 253.5 50V54C253.5 56.2091 251.709 58 249.5 58H239.5C238.948 58 238.5 57.5523 238.5 57V50Z" className={`reportcarImgIcon d4 ${data.d4Color}`}/>
            <path d="M243.776 54H242.282V49.7578H243.794C245.071 49.7578 245.854 50.5518 245.851 51.873C245.854 53.2002 245.071 54 243.776 54ZM243.161 53.2441H243.741C244.55 53.2441 244.972 52.8252 244.972 51.873C244.972 50.9297 244.55 50.5137 243.747 50.5137H243.161V53.2441ZM246.396 53.25V52.5527L248.188 49.7578H249.296V52.541H249.835V53.25H249.296V54H248.458V53.25H246.396ZM247.286 52.541H248.47V50.7246H248.429L247.286 52.5059V52.541Z" fill="white"/>
            <path d="M203.5 90C203.5 87.7909 205.291 86 207.5 86H214.5C216.709 86 218.5 87.7909 218.5 90V94C218.5 96.2091 216.709 98 214.5 98H204.5C203.948 98 203.5 97.5523 203.5 97V90Z" className={`reportcarImgIcon d3 ${data.d3Color}`}/>
            <path d="M208.832 94H207.338V89.7578H208.85C210.127 89.7578 210.909 90.5518 210.906 91.873C210.909 93.2002 210.127 94 208.832 94ZM208.217 93.2441H208.797C209.605 93.2441 210.027 92.8252 210.027 91.873C210.027 90.9297 209.605 90.5137 208.803 90.5137H208.217V93.2441ZM213.092 94.0586C212.16 94.0586 211.492 93.5459 211.475 92.8047H212.365C212.38 93.1211 212.688 93.3291 213.098 93.332C213.52 93.3291 213.821 93.0947 213.818 92.752C213.821 92.4062 213.514 92.1631 213.016 92.1602H212.623V91.5098H213.016C213.435 91.5098 213.728 91.2812 213.725 90.9473C213.728 90.6221 213.479 90.3994 213.104 90.4023C212.729 90.3994 212.421 90.6104 212.406 90.9355H211.562C211.577 90.2061 212.233 89.6992 213.104 89.6992C213.988 89.6992 214.577 90.2236 214.574 90.8945C214.577 91.3691 214.246 91.7061 213.771 91.791V91.8262C214.396 91.9023 214.738 92.2803 214.732 92.8105C214.738 93.5371 214.05 94.0586 213.092 94.0586Z" fill="white"/>
            <path d="M160.5 117C160.5 114.791 162.291 113 164.5 113H170.5C172.709 113 174.5 114.791 174.5 117V121C174.5 123.209 172.709 125 170.5 125H161.5C160.948 125 160.5 124.552 160.5 124V117Z" className={`reportcarImgIcon d2 ${data.d2Color}`}/>
            <path d="M165.417 121H163.923V116.758H165.435C166.712 116.758 167.494 117.552 167.491 118.873C167.494 120.2 166.712 121 165.417 121ZM164.802 120.244H165.382C166.19 120.244 166.612 119.825 166.612 118.873C166.612 117.93 166.19 117.514 165.388 117.514H164.802V120.244ZM168.106 121L168.101 120.361L169.624 118.961C170.02 118.583 170.233 118.349 170.233 118.012C170.233 117.634 169.949 117.399 169.56 117.402C169.161 117.399 168.9 117.651 168.903 118.059H168.065C168.062 117.229 168.678 116.699 169.571 116.699C170.479 116.699 171.083 117.218 171.083 117.953C171.083 118.437 170.849 118.832 169.976 119.623L169.331 120.25V120.273H171.142V121H168.106Z" fill="white"/>
            <path d="M112.5 136C112.5 133.791 114.291 132 116.5 132H122.5C124.709 132 126.5 133.791 126.5 136V140C126.5 142.209 124.709 144 122.5 144H113.5C112.948 144 112.5 143.552 112.5 143V136Z" className={`reportcarImgIcon d1 ${data.d1Color}`}/>
            <path d="M117.842 140H116.348V135.758H117.859C119.137 135.758 119.919 136.552 119.916 137.873C119.919 139.2 119.137 140 117.842 140ZM117.227 139.244H117.807C118.615 139.244 119.037 138.825 119.037 137.873C119.037 136.93 118.615 136.514 117.812 136.514H117.227V139.244ZM122.359 135.758V140H121.475V136.596H121.451L120.479 137.205V136.426L121.527 135.758H122.359Z" fill="white"/>
            <path d="M67.5 124C67.5 121.791 69.2909 120 71.5 120H77.5C79.7091 120 81.5 121.791 81.5 124V128C81.5 130.209 79.7091 132 77.5 132H68.5C67.9477 132 67.5 131.552 67.5 131V124Z" className={`reportcarImgIcon b2 ${data.b2Color}`}/>
            <path d="M71.1162 128V123.758H72.8037C73.7383 123.758 74.2129 124.209 74.21 124.848C74.2129 125.346 73.8848 125.659 73.4482 125.756V125.797C73.9258 125.82 74.377 126.201 74.3799 126.828C74.377 127.502 73.873 128 72.915 128H71.1162ZM71.9951 127.273H72.7393C73.249 127.276 73.4688 127.065 73.4658 126.746C73.4688 126.389 73.1963 126.137 72.7627 126.137H71.9951V127.273ZM71.9951 125.533H72.6748C73.0439 125.536 73.3223 125.328 73.3193 124.988C73.3223 124.681 73.0938 124.473 72.6924 124.473H71.9951V125.533ZM74.9131 128L74.9072 127.361L76.4307 125.961C76.8262 125.583 77.04 125.349 77.04 125.012C77.04 124.634 76.7559 124.399 76.3662 124.402C75.9678 124.399 75.707 124.651 75.71 125.059H74.8721C74.8691 124.229 75.4844 123.699 76.3779 123.699C77.2861 123.699 77.8896 124.218 77.8896 124.953C77.8896 125.437 77.6553 125.832 76.7822 126.623L76.1377 127.25V127.273H77.9482V128H74.9131Z" fill="white"/>
            <path d="M40.5 110C40.5 107.791 42.2909 106 44.5 106H49.5C51.7091 106 53.5 107.791 53.5 110V114C53.5 116.209 51.7091 118 49.5 118H41.5C40.9477 118 40.5 117.552 40.5 117V110Z" className={`reportcarImgIcon b1 ${data.b1Color}`}/>
            <path d="M44.041 114V109.758H45.7285C46.6631 109.758 47.1377 110.209 47.1348 110.848C47.1377 111.346 46.8096 111.659 46.373 111.756V111.797C46.8506 111.82 47.3018 112.201 47.3047 112.828C47.3018 113.502 46.7979 114 45.8398 114H44.041ZM44.9199 113.273H45.6641C46.1738 113.276 46.3936 113.065 46.3906 112.746C46.3936 112.389 46.1211 112.137 45.6875 112.137H44.9199V113.273ZM44.9199 111.533H45.5996C45.9688 111.536 46.2471 111.328 46.2441 110.988C46.2471 110.681 46.0186 110.473 45.6172 110.473H44.9199V111.533ZM49.666 109.758V114H48.7812V110.596H48.7578L47.7852 111.205V110.426L48.834 109.758H49.666Z" fill="white"/>
            </svg>
            <img src="/assets/images/img/car_svg_bg.png"/>
        </>
        }
        </div>
    );
}

export {ReportCarImg}