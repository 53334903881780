import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, GuideTextBox, ItemSection, ItemSectionTitle, NameTextItem, NameTextItemBox, PageSizing } from "component/app/items";
import { LoadingBox, Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { comFormat, paymentMethod, setDateTime } from "js/function";
import { QrBox } from "component/payment/item";
import { DetailItem } from "component/detailing/item";

const DetailingTicketDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [toastPopupData,setToastPopupData] =  useState(null);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const detailingApi = useGet({
        url:`/detailing/detail?detailing_reservation_id=${id}`,
        loginType:"login"
    });
    const detailingData = detailingApi?.data?.detailing_reservation;

    useEffect(() => {
        setLoadingBoxOpen(true);
        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 3000);
    }, []);

    useEffect(() => {
        if(detailingData){
            setLoadingBoxOpen(false);
        }
    }, [detailingApi]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="이용권 상세 정보"
                centerTitle={true}
                rightBtns={detailingData?.status == 1 ? true : false}
                rightBtnsArr={detailingData?.status == 1 ? [{addClass:"",contents:"구매취소",func:()=>{navigate(`/detailing/cancel/${id}`)}}] : null}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    <DetailItem
                        addClass={detailingData?.status !== 1 ? "isUseType" : ""}
                        id={detailingData?.id}
                        name={detailingData?.name||""}
                        date={detailingData ? `<span class='${detailingData?.status == 1 ? "gTextColor" : ""}'>예약일시 : ${detailingData?.date} ${detailingData?.time}</span>` : ""}
                        caption={detailingData?.contents||""}
                        textBtn={null}
                        textBtnFunc={()=>{}}
                        btnText={detailingData?.status == 1 ? "사용가능" : detailingData?.status == 2 ? "사용완료" : detailingData?.status == 3 ? "취소요청" : detailingData?.status == 4 ? "취소완료" : ""}
                        btnFunc={()=>{}}
                        textType={true}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="결제정보"
                    />
                    <NameTextItemBox>
                        <NameTextItem
                            name="상품명"
                            text={detailingData?.name ? `${detailingData?.name}` : "-"}
                        />
                        {detailingData?.car_number && (
                            <NameTextItem
                                name="귀속 차량번호"
                                text={detailingData?.car_number ? `${detailingData?.car_number}` : "-"}
                            />
                        )}
                        <NameTextItem
                            name="상품금액"
                            text={detailingData?.product_price ? `${comFormat(detailingData?.product_price||0)} 원` : "-"}
                        />
                        <NameTextItem
                            name="결제일시"
                            text={detailingData?.payment_date ? setDateTime(detailingData?.payment_date,"yymmdd","hymd") : "-"}
                        />
                        <NameTextItem
                            name="결제수단"
                            text={detailingData?.payment_method ? paymentMethod(detailingData?.payment_method) : "-"}
                        />
                        <NameTextItem
                            name="할인정보"
                            text={(detailingData?.is_second_sale === 1 ? `2회 이상 구매 할인율 ${detailingData?.second_sale_percent}% (${detailingData?.second_sale_price}원) 적용` : "") + ((detailingData?.is_second_sale === 1 && detailingData?.user_coupon_id) ? `<br/>` : "") + (detailingData?.user_coupon_id ? `${detailingData?.coupon_name} <span class="gTextColor">${comFormat(detailingData?.coupon_price)||0}원 할인 적용</span>` : "-")}
                        />
                        <NameTextItem
                            name="총 결제금액"
                            text={detailingData?.payment_price ? `${comFormat(detailingData?.payment_price||0)} 원` : "-"}
                        />
                    </NameTextItemBox>
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="이용정보"
                    />
                    <NameTextItemBox>
                        <NameTextItem
                            name="이용상태"
                            text={detailingData?.status ? `${detailingData?.status == 1 ? "사용 전" : detailingData?.status == 2 ? "사용완료" : detailingData?.status == 3 ? "취소요청" : detailingData?.status == 4 ? "취소완료" : ""}${detailingData?.updated_at ? `<br/>${setDateTime(detailingData?.updated_at,"yymmddhhttss","hymd")}`:""}` : "-"}
                        />
                    </NameTextItemBox>
                </ItemSection>
                <ItemSection>
                    <GuideTextBox
                        title="안내사항"
                        data={[
                            "본 이용권은 매장에서만 사용 가능합니다.",
                            "유효기간이 지난 이용권은 기한 연장 및 복구가 불가능합니다."
                        ]}
                    />
                </ItemSection>
                {detailingData?.status == 1 ? 
                <BtnBox
                    boxType="fixed"
                    addClass={detailingData?.is_reservation_edit == 1 ? "" : "col2"}
                >
                    <BtnItem
                        addClass={detailingData?.is_reservation_edit == 1 ? "" : "col2"}
                        contents={"QR 코드로 사용하기"}
                        disabled={false}
                        func={() => {
                            setToastPopupData({
                                addClass:null,
                                title:null,
                                closeType:true,
                                closeFuncChk:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"닫기",
                                btn0Type:"",
                                btn1:"",
                                btn1Type:"",
                                btnFunc1:()=>{},
                                textBtn:"",
                                textBtnFunc:()=>{
                                    
                                }
                            });
                        }}
                    />
                    {detailingData?.is_reservation_edit == 1 ? ""
                    : <BtnItem
                        addClass={"col2 subBtn"}
                        contents={"예약 변경"}
                        disabled={false}
                        func={() => {
                            navigate(`/detailing/ticket/ReservationEdit/${detailingData.id}`)
                        }}
                    />
                    }
                </BtnBox>
                :""}
            </ContentsSection>
            <ToastPopup
                data={toastPopupData}
            >
                <ToastBasicContents
                    title="QR코드"
                    text="타인에게 QR코드를 공유하지 마세요."
                />
                <QrBox qrImg={detailingData?.qr_code}/>
            </ToastPopup>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default DetailingTicketDetail;